<template>
  <svg
    width="50"
    height="40"
    viewBox="0 0 52 43"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.33301 21.4999H50.6663M29.083 1.95825L50.6663 21.4999L29.083 41.0416"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
